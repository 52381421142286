@import '../../../../css/variables/variables.scss';

.ourServices {
	display: flex;
	flex-direction: column;
	gap: 4rem;

	.outsourcingBlock {
		background-image: url('../../../../images/background/mainBlue.png');
	}
	.developmentBlock {
		background-image: url('../../../../images/background/mainRed.png');

		& > img {
			top: -5rem;
			width: 28rem;
			height: 25rem;
		}
	}
}

// Mobile
@media (max-width: 500px) {
	.ourServices {
		& > h2 {
			align-self: center;
		}

		&-servicesBlock {
			padding: 2rem 1.5rem;

			&-titleBlock {
				width: 100%;
				& > h3 {
					text-align: center;
				}
			}

			& > img {
				display: none;
			}

			&-serviceList {
				// grid-template-columns: repeat(2, 49%);
				gap: 1rem 2%;
			}

			& > span {
				align-self: center;
			}
		}
	}
}
