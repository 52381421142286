.feedback {
	display: grid;
	grid-template-columns: 55% 1fr;
	align-items: center;
	gap: 3.5rem;

	p {
		color: #000;
		font-size: 1.8rem;
		font-weight: 500;
		line-height: 2.8rem;
	}

	form {
		padding: 3rem;
		display: flex;
		flex-direction: column;
		align-items: center;
		gap: 1rem;
		color: #fff;
		background-color: #000;
		border-radius: 2.4rem;
		box-sizing: border-box;

		h4 {
			font-size: 1.8rem;
		}

		input,
		button {
			padding: 1rem 2rem;
			width: 100%;
			color: #fff;
			font-size: 1.4rem;
			font-family: 'Mulish';
			background-color: #282828;
			border-radius: 1rem;
			box-sizing: border-box;
		}

		button {
			background-color: #cd0205;
			text-align: center;
		}
	}
}

// Mobile
@media (max-width: 500px) {
	.feedback {
		grid-template-columns: 1fr;
	}
}
