@import '../../../../../css/variables/variables.scss';

.serviceWindow {
	padding: 3.5rem 3rem;
	display: flex;
	flex-direction: column;
	gap: 1.2rem;
	max-width: 95vw;
	max-height: 85vh;
	background-color: #fff;
	box-shadow: 1px 1px 6px 0px rgba(0, 0, 0, 0.25);
	border-radius: 2rem;
	box-sizing: border-box;

	& > h3 {
		font-size: 2.2rem;
		font-weight: 500;
	}

	&_cardList {
		padding: 0.8rem;
		display: grid;
		grid-template-columns: repeat(3, 1fr);
		gap: 0.5rem;
		max-height: 70rem;
		overflow-y: auto;

		&::-webkit-scrollbar {
			width: 0.4rem;
		}
		&::-webkit-scrollbar-thumb {
			// border-left: 0.2rem solid #fff;
			border-radius: 0.5rem;
			background-color: #eee;
		}
	}
}

.card {
	padding: 1rem 1.4rem;
	display: flex;
	flex-direction: column;
	gap: 0.5rem;
	max-width: 23.5rem;
	background-color: #fff;
	box-shadow: 1px 1px 6px 0px rgba(0, 0, 0, 0.25);
	border-radius: 1rem;
	box-sizing: border-box;

	& span {
		color: $color6;
		font-size: 0.8rem;
		font-weight: 500;
	}

	&_btnsWrp {
		padding: 1rem;
		display: flex;
		justify-content: center;
		align-items: center;
		gap: 0.8rem;
		color: #fff;
		background-color: $color5;
		border-radius: 1rem;
		box-sizing: border-box;

		& > button {
			display: flex;
			justify-content: center;
			align-items: center;

			&:nth-of-type(1) {
				transform: rotate(180deg);
			}

			& > svg {
				width: 1.6rem;
				& > g > path {
					fill: #fff;
				}
			}
		}
	}

	&_tags {
		display: flex;
		flex-wrap: wrap;
		gap: 0.2rem;

		& > p {
			padding: 0.2rem 0.4rem;
			font-size: 0.8rem;
			color: #fff;
			background-color: $color6;
			text-transform: uppercase;
			border-radius: 0.3rem;
			box-sizing: border-box;
		}
	}
}

// Mobile
@media (max-width: 500px) {
	.serviceWindow {
		// max-height: 55rem;

		&_cardList {
			grid-template-columns: repeat(2, 1fr);
		}
	}
}
