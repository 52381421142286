@import '../../../../../css/variables/variables.scss';

.servicesBlock {
	padding: 3rem 4rem 1.6rem;
	display: flex;
	flex-direction: column;
	gap: 2rem;
	background-repeat: no-repeat;
	background-size: cover;
	border-radius: 2.4rem;
	box-sizing: border-box;
	position: relative;

	&_titleBlock {
		display: flex;
		flex-direction: column;
		gap: 1rem;
		width: 65%;
		color: #fff;
		font-weight: 500;
	}

	& > img {
		position: absolute;
		top: -7.5rem;
		right: 4rem;
		width: 17.5rem;
		height: 23.5rem;
	}

	&_serviceList {
		display: grid;
		grid-template-columns: repeat(2, 49%);
		gap: 1.3rem 2%;
	}

	& > span {
		align-self: flex-end;
		display: flex;
		align-items: center;
		gap: 1.6rem;
		color: #fff;
	}
}

.service {
	padding: 1rem 2rem;
	display: flex;
	flex-direction: column;
	gap: 0.5rem;
	font-size: 1.2rem;
	font-weight: 500;
	background-color: #fff;
	border-radius: 2rem;
	box-sizing: border-box;

	h4 {
		color: $color2;
		font-size: 1.6rem;
		font-weight: 700;
	}
}

// Mobile
@media (max-width: 500px) {
	.servicesBlock {
		padding: 2rem 1.5rem;

		&_titleBlock {
			width: 100%;
			& > h3 {
				text-align: center;
			}
		}

		& > img {
			display: none;
		}

		&_serviceList {
			// grid-template-columns: repeat(2, 49%);
			gap: 1rem 2%;
		}

		& > span {
			align-self: center;
		}
	}
}
