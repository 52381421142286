.titleSectionBgr {
	& > div:nth-of-type(1) {
		background-image: url(../../../images/background/zennoLabBgr.png);

		& > div:nth-of-type(2) {
			& > h2 > svg {
				width: 24rem;
				height: 4.4rem;
			}

			& > p {
				text-shadow: none;
			}
		}
	}
}
