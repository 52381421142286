.youWillGet {
	display: flex;
	flex-direction: column;
	gap: 4rem;

	&_cardList {
		display: grid;
		grid-template-columns: repeat(2, 49%);
		gap: 1.3rem 2%;
	}
}

.card {
	padding: 1.2rem;
	display: flex;
	align-items: center;
	gap: 0.8rem;
	font-size: 1.2rem;
	font-weight: 500;
	background-color: #fbfbfb;
	border-radius: 2rem;
	box-sizing: border-box;

	& > svg {
		width: 4.5rem;
		height: 4.5rem;
	}

	& > div {
		display: flex;
		flex-direction: column;
		gap: 0.5rem;

		& > h4 {
			font-size: 1.6rem;
			// color: $color2;
		}
	}
}

// Mobile
@media (max-width: 500px) {
	.youWillGet > h3 {
		align-self: center;
	}

	.card {
		& > svg {
			width: 7.5rem;
		}
	}
}
