input[type='file'],
input[type='checkbox'] {
	width: 0;
}
.input {
	/* margin: 0 0 10px; */
	/* padding: 10px 15px; */
	width: 100%;
	/* font-size: 13px; */
	/* line-height: 16px; */
	/* color: inherit; */
	background-color: transparent;
	border-radius: 4px;
	border: 1px solid #d0d0d0;
	box-sizing: border-box;
}

.inputPassword {
	display: flex;
	position: relative;
}
.inputPassword svg {
	position: absolute;
	top: 50%;
	right: 0.9rem;
	transform: translateY(-50%);
}
.inputPassword svg:hover {
	cursor: pointer;
}
