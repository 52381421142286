@import '../../../css/variables/variables.scss';

.rightPanelBlock {
	&_rightPanelWrp {
		position: sticky;
		top: 0;

		&_rightPanel {
			padding: 2rem 3rem;
			display: flex;
			flex-direction: column;
			gap: 1.5rem;
			height: fit-content;
			color: black;
			border-radius: 2rem;
			box-shadow: 1px 1px 6px 0px rgba(0, 0, 0, 0.25);
			box-sizing: border-box;

			& > div:nth-of-type(1) {
				display: flex;
				justify-content: space-between;
				align-items: center;

				& > button:nth-of-type(1) {
					display: flex;
					align-items: center;
					justify-content: center;
					gap: 1rem;

					& > svg {
						width: 3rem;
						height: 3rem;

						& > g > path {
							fill: $color4;
						}
					}
				}

				& > button:nth-of-type(2) {
					color: $color4;
				}
			}

			& > button {
				display: flex;
				align-items: center;
				justify-content: center;
				gap: 1rem;

				& > svg {
					width: 10rem;
					height: 7rem;
				}
			}

			&_ourProjects {
				display: flex;
				flex-direction: column;
				align-items: center;
				gap: 1rem;

				h5 {
					font-size: 2rem;
					font-weight: 500;
				}

				a,
				button {
					padding: 1rem;
					display: flex;
					justify-content: center;
					align-items: center;
					gap: 1rem;
					width: 100%;
					color: #fff;
					border-radius: 1rem;
					box-sizing: border-box;
				}
				a {
					&:nth-of-type(1) {
						font-family: 'Roboto';
						font-size: 2rem;
						font-weight: 900;
						background: #182c2d;

						& > svg {
							width: 4rem;
							height: 3rem;
						}

						span {
							color: #d66b45;
						}
					}

					&:nth-of-type(2) {
						font-family: 'Snell-Roundhand';
						font-size: 2.3rem;
						background: linear-gradient(180deg, #0a42d3 0%, #1f7fd8 100%);
						align-items: baseline;

						span {
							color: red;
							font-size: 4rem;
							line-height: 3rem;
						}
					}
				}

				button {
					&:nth-of-type(1) {
						background: linear-gradient(180deg, #18a3fa 0%, #39c051 100%);
						& > svg {
							width: 14rem;
							height: 2.6rem;
						}
					}

					&:nth-of-type(2) {
						background: linear-gradient(180deg, #1b2a50 0%, #3d5fb3 100%);
						& > svg {
							width: 3rem;
							height: 3rem;
						}
					}

					&:nth-of-type(3) {
						background: linear-gradient(180deg, #501b1b 0%, #b33d3d 100%);
						& > svg {
							width: 3rem;
							height: 3rem;
						}
					}
				}
			}
		}

		& > button {
			display: none;
		}
	}
}

// Tablet
@media (max-width: 900px) {
	.rightPanelBlock {
		position: absolute;
		top: 0;
		right: 0;
		height: 100%;

		&_rightPanelWrp {
			&_rightPanel {
				padding: 4.5rem 0 3rem;
				top: 0;
				right: 0;
				width: 0;
				height: 100%;
				background-color: #fff;
				border-radius: 0 0 0 2rem;
				box-shadow: -2px 2px 4px 0px rgba(0, 0, 0, 0.25);
				overflow: hidden; // auto
				transition: all 1s;
				float: right;

				&_open {
					padding: 4.5rem 12rem 2rem 3rem;
					width: 100%;
				}

				& > a,
				&_ourProjects {
					min-width: max-content;
				}
			}

			& > button {
				padding: 2.5rem 1.2rem;
				position: absolute;
				top: 3.5rem;
				right: 4.5rem;
				display: flex;
				align-items: center;
				background-color: #fff;
				border-radius: 1rem;
				box-shadow: 1px 1px 6px 0px rgba(0, 0, 0, 0.25);
				box-sizing: border-box;
				z-index: 1;

				& > #menuToggle {
					display: none;
				}
				.menuToggleLabel {
					position: absolute;
					left: 0;
					height: 100%;
					width: 100%;
					cursor: pointer;
					z-index: 1;
				}
				.burgerMenu,
				.burgerMenu::before,
				.burgerMenu::after {
					display: block;
					height: 0.45rem;
					width: 3.5rem;
					transition: transform 1s cubic-bezier(0.23, 1, 0.32, 1);
					background-color: black;
					border-radius: 6rem;
				}
				.burgerMenu {
					position: relative;
				}
				.burgerMenu::before {
					content: '';
					position: absolute;
					bottom: 1rem;
				}
				.burgerMenu::after {
					content: '';
					position: absolute;
					top: 1rem;
				}

				#menuToggle:checked ~ .burgerMenu::before {
					bottom: 0;
					transform: rotate(135deg);
				}
				#menuToggle:checked ~ .burgerMenu {
					background: transparent;
				}
				#menuToggle:checked ~ .burgerMenu::after {
					top: 0;
					transform: rotate(-135deg);
				}
			}
		}
	}
}

// Mobile
@media (max-width: 500px) {
	.rightPanelBlock {
		&_rightPanelWrp {
			& > button {
				right: 1.5rem;
			}
		}
	}
}
