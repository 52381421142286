.legalInfo {
	display: flex;
	flex-direction: column;
	gap: 4rem;

	&_docList {
		display: flex;
		flex-wrap: wrap;
		gap: 1rem;

		&_docBlock {
			max-width: 25rem;
			& > img {
				width: 100%;
				border-radius: 2rem;
				border: 1px solid #cacaca;
				box-shadow: 1px 1px 4px 0px rgba(0, 0, 0, 0.35);
			}
			& > a {
				display: flex;
				gap: 1rem;
				font-size: 1.4rem;
				text-decoration: underline;
			}
		}
	}
}

.contacts {
	display: flex;
	flex-direction: column;
	gap: 1rem;

	// & > h3 {
	// 	font-size: 2.6rem;
	// }

	& > div {
		display: grid;
		grid-template-columns: 55% 1fr;
		gap: 1rem 3.5rem;
	}
}

// Mobile
@media (max-width: 500px) {
	.legalInfo {
		& > h2 {
			align-self: center;
		}

		&_docList {
			justify-content: center;
			text-align: center;
		}
	}

	.contacts {
		& > div {
			grid-template-columns: 1fr;
		}
	}
}
