@import '../../../css/variables/variables.scss';

.container {
	display: grid;
	grid-template-columns: max-content 1fr;
	align-items: center;
	gap: 4px;

	& > input[type='checkbox'] {
		position: absolute;
		visibility: hidden;
	}

	& > div {
		margin: 3px 3px;
		display: block;
		width: 18px;
		height: 18px;
		border: 2px solid $color4;
		border-radius: 3px;
		box-sizing: border-box;
		position: relative;
	}

	& > svg {
		width: 2.4rem;
		height: 2.4rem;

		& > path {
			stroke: $color4;
		}
	}
}
