@import './variables/variables.scss';

// Mulish
@font-face {
	font-family: 'Mulish';
	src: url(../fonts/Mulish/Mulish-Regular.ttf);
	font-weight: 400;
	font-style: normal;
}

@font-face {
	font-family: 'Mulish';
	src: url(../fonts/Mulish/Mulish-Bold.ttf);
	font-weight: 700;
	font-style: normal;
}

@font-face {
	font-family: 'Mulish';
	src: url(../fonts/Mulish/Mulish-Light.ttf);
	font-weight: 300;
	font-style: normal;
}

// Roboto
@font-face {
	font-family: 'Roboto';
	src: url(../fonts/Roboto/Roboto-Regular.ttf);
	font-weight: 400;
	font-style: normal;
}

@font-face {
	font-family: 'Roboto';
	src: url(../fonts/Roboto/Roboto-Bold.ttf);
	font-weight: 700;
	font-style: normal;
}

@font-face {
	font-family: 'Roboto';
	src: url(../fonts/Roboto/Roboto-Light.ttf);
	font-weight: 300;
	font-style: normal;
}

/* Snell-Roundhand */
@font-face {
	font-family: 'Snell-Roundhand';
	src: url(../fonts/SnellRoundhand/Snell-Roundhand-Bold.ttf),
		url(../fonts/SnellRoundhand/Snell-Roundhand-Bold.otf),
		url(../fonts/SnellRoundhand/Snell-Roundhand-Bold.woff);
	font-weight: 700;
	font-style: normal;
}

html {
	font-size: 10px;
}

body {
	padding: 3rem 8.5rem;
	margin: 0 auto;
	max-width: 128rem;
	color: $color1;
	font-family: 'Mulish';
	font-weight: 400;
	font-style: normal;
	font-size: 1.6rem;
	line-height: normal;
	box-sizing: border-box;
}

h1,
h2,
h3,
h4,
h5,
h6,
p,
ul {
	margin: 0;
	padding: 0;
}

a {
	text-decoration: none;
	color: $color1;
}

input,
button {
	padding: 0;
	font-family: 'Mulish';
	font-size: 1.6rem;
	background-color: inherit;
	border-style: none;
}
button:hover {
	cursor: pointer;
}
input:focus-visible,
select:focus-visible {
	outline: none;
}

.pageContainer {
	display: flex;
	flex-direction: column;
	gap: 3rem;
}

.pageTitle {
	font-size: 5rem;
	font-weight: 700;
}

.sectionTitle {
	font-size: 3.6rem;
	font-weight: 700;
}

// Laptop
@media (max-width: 1280px) {
	html {
		font-size: 8px;
	}
}
@media (max-width: 1020px) {
	html {
		font-size: 7px;
	}
}

// Tablet
@media (max-width: 900px) {
	html {
		font-size: 10px;
	}

	body {
		padding: 2.5rem;
		max-width: 74.4rem;
	}

	.pageTitle {
		font-size: 4rem;
	}
}
@media (max-width: 740px) {
	html {
		font-size: 8px;
	}
}
@media (max-width: 600px) {
	html {
		font-size: 7px;
	}
}

// Mobile
@media (max-width: 500px) {
	html {
		font-size: 9px;
	}
}
@media (max-width: 440px) {
	html {
		font-size: 8px;
	}
}
@media (max-width: 340px) {
	html {
		font-size: 7px;
	}
}
