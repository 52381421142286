.operatingProcedure {
	display: flex;
	flex-direction: column;
	gap: 4rem;

	&_procedureList {
		display: grid;
		grid-template-columns: repeat(2, 49%);
		gap: 1.3rem 2%;
	}
}

.procedure {
	padding: 1.4rem;
	display: flex;
	flex-direction: column;
	align-items: center;
	gap: 0.5rem;
	text-align: center;
	font-size: 1.2rem;
	background-color: #fbfbfb;
	border-radius: 2rem;
	box-sizing: border-box;

	& > h3 {
		color: black;
		font-size: 3.5rem;
		font-weight: 700;
	}

	& > h4 {
		// color: $color2;
		font-size: 1.8rem;
		font-weight: 700;
	}
}

// Mobile
@media (max-width: 500px) {
	.operatingProcedure > h3 {
		align-self: center;
	}
}
