.cookiesBlock {
	position: fixed;
	padding: 1.5rem 0;
	display: flex;
	flex-direction: column;
	align-items: center;
	text-align: center;
	gap: 1rem;
	left: 0;
	bottom: 0;
	width: 100%;
	color: #fff;
	background-color: #23282d;
	z-index: 10;

	& > p {
		padding: 0 8.5rem;
		max-width: 128rem;
		box-sizing: border-box;
	}

	& > div {
		display: flex;
		gap: 1rem;

		& > button {
			padding: 0.5rem 1.5rem;
			color: #fff;
			background-color: inherit;
			border: 1px solid #fff;
			border-radius: 3px;
			box-sizing: border-box;

			&:hover {
				cursor: pointer;
				color: #23282d;
				background-color: #fff;
			}
			&:active {
				cursor: pointer;
				color: #fff;
				background-color: inherit;
			}
		}
	}
}

// Tablet
@media (max-width: 900px) {
	.cookiesBlock > p {
		padding: 0 2.5rem;
	}
}
