.product {
	display: flex;
	flex-direction: column;
	gap: 4rem;

	&_head {
		display: flex;
		align-items: center;
		justify-content: space-between;

		&_logo {
			display: flex;
			align-items: center;
			gap: 1.5rem;

			& > svg {
				width: 5rem;
				height: 5rem;
			}
		}

		& > span {
			display: flex;
			align-items: center;
			gap: 1.5rem;

			& > a > button {
				background: linear-gradient(180deg, #18a3fa 0%, #39c051 100%);

				& > svg > g > path {
					fill: #fff;
				}
			}
		}
	}

	// &-serviceList {
	// 	display: grid;
	// 	grid-template-columns: repeat(2, 49%);
	// 	gap: 1.3rem 2%;

	// 	&_service {
	// 		padding: 1rem 2rem;
	// 		display: flex;
	// 		flex-direction: column;
	// 		gap: 0.5rem;
	// 		font-size: 1.2rem;
	// 		font-weight: 500;
	// 		background-color: #fbfbfb;
	// 		border-radius: 2rem;
	// 		border-left: 1px solid #6bb5fb;
	// 		box-sizing: border-box;

	// 		h4 {
	// 			color: $color2;
	// 			font-size: 1.6rem;
	// 			font-weight: 700;
	// 		}
	// 	}
	// }
}

// Mobile
@media (max-width: 500px) {
	.product {
		&_head {
			&_logo {
				& > h3 {
					font-size: 3rem;
				}
			}

			& > span {
				font-size: 0;
			}
		}
	}
}
