.form {
	padding: 1rem 2rem;
	display: flex;
	flex-direction: column;
	align-items: center;
	gap: 1rem;
	background-color: #ffffff;
	border-radius: 2rem;
	box-shadow: 1px 1px 6px 0px rgba(0, 0, 0, 0.25);
	box-sizing: border-box;
}

.title {
	margin: 0;
	font-size: 1.6rem;
	/* font-size: 28px; */
	font-weight: 500;
}
