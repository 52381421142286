form.form {
	padding: 2.5rem 3.75rem 1.25rem;
	width: 43rem;
	gap: 1rem;
	/* color: var(--color3); */
}

/* .link {
	align-self: flex-end;
	line-height: 17px;
} */

.form h4 {
	margin: 0;
	font-size: 1.5em;
	/* font-size: 24px; */
}

/* Phone */
@media (max-width: 685px) {
	form.form {
		// padding: 1.5rem 3rem 0.5rem;
		// width: 24rem;
		gap: 0.5rem;
	}
}
