@import '../../../../css/variables/variables.scss';

// navigate
.navigate {
	display: flex;
	gap: 5px;
	align-items: center;
	align-self: flex-end;
	line-height: 18px;
	&:hover {
		cursor: pointer;
	}

	& > svg {
		width: 2rem;
		height: 1rem;

		& > g > path {
			fill: $color1;
		}
	}
}

.btnBack {
	align-self: flex-start;

	& > svg {
		transform: rotate(180deg);
	}
}

// inputBlock
.inputBlock,
.inputError {
	display: flex;
	flex-direction: column;
	gap: 10px;
	width: 100%;

	& input {
		padding: 10px 20px;
		text-align: center;
		font-size: 1em;
		&::placeholder {
			color: #cecece;
		}
	}
}

.inputError input {
	border: 1px solid $color4;
}

.error {
	align-self: flex-start;
	color: $color4;
}

// rememberBlock
.rememberBlock {
	display: flex;
	justify-content: space-between;
	width: 100%;

	& > div {
		display: flex;
		align-items: center;
		gap: 4px;
		color: $color4;

		&:hover {
			cursor: pointer;
		}
	}
}

// entryBlock
.entryBlock {
	display: flex;
	flex-direction: column;
	gap: 0.4rem;

	& > button:disabled {
		cursor: auto;
		opacity: 0.3;
		color: #fff;
		background-color: $color4;
	}

	& > div {
		display: flex;
		flex-direction: column;
		gap: 0.4rem;
		text-align: justify;
		font-size: 0.7em;
		line-height: normal;

		& > label {
			display: grid;
			grid-template-columns: 18px 1fr;
			gap: 1rem;

			& > p > a {
				color: $color4;
			}
		}
	}
}

// loginOptionsBlock
.loginOptionsBlock {
	display: flex;
	flex-direction: column;
	align-items: center;
	gap: 5px;

	& > p {
		font-size: 1.1em;
	}

	& > .icons {
		display: flex;
		justify-content: center;
		align-items: center;
		flex-wrap: wrap;
		gap: 8px;

		& > svg {
			width: 3.125rem;
			height: 3.125rem;
			&:hover {
				cursor: pointer;
			}
		}
	}
}

// registerBlock
.registerBlock {
	text-align: center;

	& > span {
		color: $color4;
		&:hover {
			cursor: pointer;
		}
	}
}
