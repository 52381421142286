@import '../../../../css/variables/variables.scss';

.historySection {
	display: grid;
	grid-template-columns: 1fr 1fr;
	gap: 2rem;
}

.historyBlock {
	padding: 2.4rem 3.6rem;
	display: flex;
	flex-direction: column;
	gap: 1.5rem;
	border-radius: 2rem;
	box-shadow: 1px 1px 6px 0px rgba(0, 0, 0, 0.25);
	box-sizing: border-box;

	& > h3 {
		font-size: 2.2rem;
	}

	& > div {
		display: flex;
		flex-direction: column;
		gap: 1rem;
		height: 30rem;
		overflow-y: auto;

		&::-webkit-scrollbar {
			width: 1.2rem;
		}
		&::-webkit-scrollbar-thumb {
			border-left: 0.8rem solid #fff;
			// border-radius: 20rem;
			background-color: #eee;
		}
	}

	& > button {
		display: none;
	}
}

.replenishmentCard {
	padding: 0.5rem 1rem;
	display: flex;
	justify-content: space-between;
	align-items: center;
	color: #000;
	background-color: #f5f5f5;
	border-radius: 6px;
	box-sizing: border-box;

	& > p {
		font-weight: 700;
	}

	& > div {
		font-size: 1rem;
	}
}

.purchaseCard {
	padding: 0.5rem 1rem;
	display: flex;
	flex-direction: column;
	gap: 0.6rem;
	color: #000;
	background-color: #f5f5f5;
	border-radius: 6px;
	box-sizing: border-box;

	& > p > span {
		color: $color1;
	}

	& > div {
		align-self: flex-end;
		font-size: 1rem;
	}
}

// Mobile
@media (max-width: 500px) {
	.historySection {
		grid-template-columns: 1fr;
	}
	.historyBlock {
		padding: 0;
		box-shadow: none;

		& > div {
			height: auto;
		}

		& > button {
			display: block;
		}
	}
}
