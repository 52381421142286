@import '../../../../css/variables/variables.scss';

.titleSectionBgr {
	& > div:nth-of-type(1) {
		background-image: url(../../../../images/background/development.png);
	}
}

.service {
	border-left: 1px solid #fb6b6b;

	& > h4 {
		color: $color3;
	}
}

.procedure {
	& > h4 {
		color: $color3;
	}
}

.card {
	& > div {
		& > h4 {
			color: $color3;
		}
	}
}
