@import '../../../css/variables/variables.scss';

.isHostingSection {
	margin: 0 auto;
	max-width: 650px;
	font-family: Arial, Helvetica, sans-serif;
	box-shadow: 0 5px 10px 0 rgba(0, 0, 0, 0.05), 0 0 4px 0 rgba(0, 0, 0, 0.05);

	&_head {
		padding: 0.8rem 3rem;
		display: flex;
		justify-content: space-between;
		align-items: center;
		color: #fff;
		background-color: #000;

		& > svg {
			width: 9.4rem;
			height: 1.8rem;
		}

		& > div:nth-of-type(1) {
			display: flex;
			align-items: center;
			gap: 1rem;

			& > svg {
				width: 3rem;
				height: 2rem;

				& > g > path,
				& > g > g > path {
					fill: #fff;
				}
			}
		}
	}

	&_main {
		padding: 1.5rem 3rem;
		display: flex;
		flex-direction: column;
		gap: 1.5rem;
		color: #000;
		box-sizing: border-box;

		&_info {
			padding: 1.4rem;
			display: flex;
			flex-direction: column;
			gap: 1rem;
			font-size: 1.4rem;
			background-color: $color7;
			box-sizing: border-box;

			& > ul {
				padding: 0 0 0 2rem;
			}
		}

		&_dataPay {
			display: flex;
			flex-direction: column;
			gap: 1.5rem;

			& > div:nth-of-type(1),
			& > div:nth-of-type(2) {
				padding: 1.4rem 3rem;
				display: flex;
				justify-content: space-between;
				align-items: center;
				font-size: 1.8rem;
				border: 1px solid #f4f4f4;
				box-sizing: border-box;

				& > p:nth-of-type(1) {
					color: #525252;
				}
			}

			& > div:nth-of-type(3) {
				display: grid;
				grid-template-columns: 1fr 1fr;

				& > button {
					padding: 2rem 1rem;
					text-align: start;
				}
				& > button:nth-of-type(1) {
					color: #525252;
				}
				& > button:nth-of-type(2) {
					background-color: #fcc238;
					&:disabled {
						opacity: 0.5;
					}
				}
			}
		}

		&_agreementBlock {
			display: flex;
			flex-direction: column;
			gap: 0.4rem;
			font-size: 1.2rem;

			a {
				color: #546699;
			}

			label > svg > path {
				stroke: #000;
			}
		}
	}
}
