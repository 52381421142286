@import '../../../../css/variables/variables.scss';

.ourProjects {
	display: flex;
	flex-direction: column;
	gap: 4rem;

	&_projects {
		display: flex;
		flex-direction: column;
		gap: 2rem;

		&_project {
			padding: 3rem;
			display: flex;
			flex-direction: column;
			gap: 2rem;
			border-radius: 2.4rem;
			box-sizing: border-box;

			&_titleBlock {
				display: flex;
				justify-content: space-between;
				align-items: center;
				color: #fff;
				font-size: 1.8rem;
				font-weight: 500;

				& > a {
					width: min-content;
				}
			}
		}

		&_project:nth-of-type(1) {
			background: linear-gradient(180deg, #18a3fa 0%, #39c051 100%);

			.ourProjects_projects_project_titleBlock {
				.logo {
					width: 18rem;
					height: 3.2rem;
				}
			}

			.ourProjects_projects_project_serviceList {
				display: grid;
				grid-template-columns: 35% repeat(3, 1fr);
				gap: 2rem;

				& > p:nth-of-type(1) {
					font-size: 1.8rem;
					color: #fff;
				}

				&_service {
					padding: 1rem;
					display: flex;
					flex-direction: column;
					gap: 0.8rem;
					font-size: 1.1rem;
					background-color: #fff;
					border-radius: 1.4rem;
					box-sizing: border-box;

					& > svg {
						align-self: center;
						width: 10rem;
						height: 2rem;
					}
				}
			}
		}

		&_project:nth-of-type(2) {
			background: #182c2d;

			.ourProjects_projects_project_titleBlock {
				&_logo {
					display: flex;
					gap: 0.8rem;
					font-size: 2.4rem;
					font-weight: 900;

					& > svg {
						width: 3.8rem;
						height: 3rem;
					}

					p > span {
						color: #d66b45;
					}
				}
			}

			.ourProjects_projects_project_info {
				padding: 0 1.5rem;
				width: 65%;
				color: #fff;
				font-family: 'Roboto';
				font-size: 1.4rem;
				font-weight: 400;
				line-height: 2.8rem;
				border-left: 1px solid #c63f3d;
				box-sizing: border-box;
			}

			.ourProjects_projects_project_advantages {
				display: flex;
				gap: 5rem;
				color: #fff;
				font-family: 'Roboto';
				font-size: 1.4rem;
				box-sizing: border-box;

				p {
					padding: 0 1.5rem;
					border-left: 1px solid #c63f3d;
					box-sizing: border-box;
				}
			}
		}

		&_project:nth-of-type(3) {
			background: linear-gradient(180deg, #0a42d3 0%, #1f7fd8 100%);

			.ourProjects_projects_project_titleBlock {
				&_logo {
					display: flex;
					align-items: baseline;
					gap: 0.5rem;
					font-family: 'Snell-Roundhand';
					font-size: 3rem;
					font-weight: 700;

					& > span {
						color: red;
						font-size: 5.4rem;
						line-height: 2rem;
					}
				}

				& > p {
					width: 50%;
				}
			}

			.ourProjects_projects_project_cardList {
				display: grid;
				grid-template-columns: repeat(2, 1fr);
				gap: 1rem 2rem;

				&_card {
					padding: 1rem 1.6rem 1rem 7.5rem;
					background-color: #fcfcfc;
					border: 1px solid #d1d1d1;
					border-radius: 1.2rem;
					box-sizing: border-box;
					position: relative;

					&_circleWithShadow {
						position: absolute;
						top: -0.9rem;
						left: 1.25rem;
						display: flex;
						justify-content: center;
						align-items: center;
						width: 5.4rem;
						height: 5.4rem;
						background: linear-gradient(180deg, #0a42d3 0%, #1f7fd8 100%);
						border-radius: 50%;
						box-sizing: border-box;

						& > svg {
							width: 3.8rem;
							height: 3.8rem;
						}
					}
					&_circleWithShadow::after {
						content: '';
						position: absolute;
						width: 2.5rem;
						height: 0.25rem;
						bottom: -0.7rem;
						left: 50%;
						transform: translate(-50%);
						margin-right: -50%;
						background-color: rgba(74, 130, 239, 0.7);
						filter: blur(0.13rem);
						border-radius: 50%;
					}

					&_title {
						margin: 0 0 0.8rem;
						font-size: 1.3rem;
						font-weight: 400;
					}

					&_text {
						font-size: 1rem;
						line-height: 1.1rem;
					}
				}
				&_card:nth-of-type(2) {
					.ourProjects_projects_project_cardList_card_circleWithShadow {
						& > svg {
							width: 3.3rem;
							height: 3.3rem;
						}
					}
				}
			}
		}
	}
}

// Mobile
@media (max-width: 500px) {
	.ourProjects {
		& > h3 {
			align-self: center;
		}

		&_projects {
			&_project {
				padding: 2rem 1.5rem;
			}
			&_project:nth-of-type(1) {
				.ourProjects_projects_project_serviceList {
					display: grid;
					grid-template-columns: 1fr 1fr;
					grid-template-areas: 'text text';
					gap: 0.5rem 1rem;

					& > p {
						grid-area: text;
						width: 100%;
					}
					&_service {
						font-size: 1.3rem;
						width: 100%;
						min-height: 15rem;
					}
				}
			}

			&_project:nth-of-type(2) {
				.ourProjects_projects_project_titleBlock {
					display: grid;
					grid-template-areas: 'logo link' 'text text';
					gap: 1rem;

					&_logo {
						grid-area: logo;
						justify-self: start;
					}

					& > p {
						grid-area: text;
						width: 100%;
					}
					& > a {
						grid-area: link;
						justify-self: end;
					}
				}

				.ourProjects_projects_project_info {
					width: 100%;
				}

				.ourProjects_projects_project_advantages {
					flex-direction: column;
					gap: 2rem;
				}
			}

			&_project:nth-of-type(3) {
				.ourProjects_projects_project_titleBlock {
					display: grid;
					grid-template-areas: 'logo link' 'text text';
					gap: 1rem;

					&_logo {
						grid-area: logo;
						justify-self: start;
					}
					& > p {
						grid-area: text;
						width: 100%;
					}
					& > a {
						grid-area: link;
						justify-self: end;
					}
				}
				.ourProjects_projects_project_cardList {
					grid-template-columns: 1fr;
				}
			}
		}
	}
}
