.container {
	position: fixed;
	top: 0;
	left: 0;
	display: flex;
	justify-content: center;
	align-items: center;
	height: 100vh;
	width: 100vw;
	background: rgba(255, 255, 255, 0.3);
	backdrop-filter: blur(5px);
	z-index: 3;
}

.window {
	/* background: #fff;
	border-radius: 30px; */
	/* max-height: 50vh; */
	position: relative;
}

.cross {
	width: 1.25rem;
	height: 1.25rem;
	position: absolute;
	top: 1.25rem;
	right: 1.25rem;
}
.cross::after,
.cross::before {
	position: absolute;
	content: '';
	width: 1.75rem;
	height: 2px;
	top: 0.563rem;
	left: -0.25rem;
	background-color: #d3d3da;
	border-radius: 2px;
	transform: rotate(45deg);
}
.cross::after {
	transform: rotate(-45deg);
}
.cross:hover {
	cursor: pointer;
}
