@import '../../../../css/variables/variables.scss';

.purchasing {
	padding: 2.4rem 3.6rem;
	display: flex;
	flex-direction: column;
	gap: 2rem;
	border-radius: 2rem;
	box-shadow: 1px 1px 6px 0px rgba(0, 0, 0, 0.25);
	box-sizing: border-box;

	& h4 {
		font-weight: 400;
	}

	&_publisherBlock {
		display: flex;
		flex-direction: column;
		gap: 1rem;

		&_publisherList {
			display: flex;
			flex-wrap: wrap;
			gap: 1.3rem;
		}
	}

	&_redirectBlock {
		display: flex;
		flex-direction: column;
		gap: 1rem;

		& > div:nth-of-type(1) {
			padding: 2rem;
			display: flex;
			flex-direction: column;
			gap: 1rem;
			background-color: $color7;
			border-radius: 0.6rem;
			box-sizing: border-box;

			& > h3 {
				display: flex;
				align-items: center;
				gap: 1rem;

				& > svg {
					width: 2.4rem;
					height: 2.4rem;
				}
			}
		}
		& > a {
			align-self: flex-end;

			& > button {
				padding: 1rem 5rem;
			}
		}
	}

	&_productBlock {
		display: flex;
		flex-direction: column;
		gap: 1rem;

		&_list {
			padding: 0.6rem 1.5rem;
			font-size: 1.4rem;
			font-weight: 500;
			background-color: #f5f5f5;
			border: none;
			border-radius: 0.6rem;
			box-sizing: border-box;
		}

		// &_content {
		// 	display: flex;
		// 	flex-wrap: wrap;
		// 	gap: 1rem;
		// }
	}

	&_servicePayBlock {
		display: flex;
		flex-direction: column;
		gap: 1.5rem;

		& > button:nth-of-type(1) {
			padding: 0.5rem 1.5rem;
			width: max-content;
			font-size: 1.4rem;

			&:disabled {
				cursor: auto;
				opacity: 0.3;
				color: #fff;
				background-color: #2c4582;
			}
		}

		&_cartBlock {
			display: flex;
			flex-direction: column;
			gap: 1.2rem;

			& > div {
				display: flex;
				flex-direction: column;
				gap: 0.8rem;
			}
		}

		&_payBlock {
			display: flex;
			justify-content: space-between;
			align-items: center;
			gap: 1rem;

			& > button {
				width: 20rem;
			}

			& > p {
				font-weight: 700;

				& > span {
					font-weight: 400;
				}
			}
		}
	}
}

.publisher {
	padding: 2rem 0;
	display: flex;
	justify-content: center;
	align-items: center;
	max-width: 13rem;
	width: 100%;
	color: #fff;
	opacity: 0.4;
	border-radius: 1rem;
	box-sizing: border-box;
	&:hover {
		cursor: pointer;
	}

	&:nth-of-type(1) {
		background: linear-gradient(180deg, #18a3fa 0%, #39c051 100%);

		& > svg {
			width: 10rem;
			height: 2rem;
		}
	}

	&:nth-of-type(2) {
		display: flex;
		align-items: center;
		gap: 0.3rem;
		font-family: 'Roboto';
		font-size: 1.1rem;
		font-weight: 900;
		background: #182c2d;

		& > svg {
			width: 2rem;
			height: 2rem;
		}

		& > p {
			display: flex;

			& > span {
				color: #d66b45;
			}
		}
	}

	&:nth-of-type(3) {
		font-family: 'Snell-Roundhand';
		font-size: 1.4rem;
		background: linear-gradient(180deg, #0a42d3 0%, #1f7fd8 100%);

		& > p > span {
			font-size: 2.5rem;
			line-height: 2rem;
			color: red;
		}
	}

	&:nth-of-type(4) {
		gap: 0.3rem;
		font-size: 1rem;
		background: linear-gradient(180deg, #1b2a50 0%, #3d5fb3 100%);

		& > svg {
			width: 2rem;
			height: 2rem;
		}
	}

	&:nth-of-type(5) {
		gap: 0.3rem;
		font-size: 1rem;
		background: linear-gradient(180deg, #501b1b 0%, #b33d3d 100%);

		& > svg {
			width: 2rem;
			height: 2rem;
		}
	}
}

.active {
	opacity: 1;
	// box-shadow: 1px 1px 8px 1px rgba(0, 0, 0, 0.6);
}

.product {
	padding: 1rem;
	display: flex;
	flex-direction: column;
	align-items: center;
	gap: 1rem;
	width: 14rem;
	height: 14rem;
	font-size: 1.1rem;
	border-radius: 14px;
	box-shadow: 1px 1px 6px 0px rgba(0, 0, 0, 0.25);
	box-sizing: border-box;
	&:hover {
		cursor: pointer;
	}

	& > svg {
		width: 10rem;
		height: 1.6rem;
	}
}

.cartCard {
	padding: 0.5rem 1.5rem;
	display: flex;
	flex-direction: column;
	gap: 0.4rem;
	font-size: 1.4rem;
	border: 1px solid #e4e4e4;
	border-radius: 0.6rem;
	box-sizing: border-box;
	position: relative;

	&_name {
	}

	&_description {
	}

	&_price {
		display: grid;
		grid-template-columns: 38% 50%;
		justify-content: space-between;

		& span {
			color: $color6;
		}

		&_servicePrice,
		&_totalPrice {
			display: flex;
			align-items: center;
			justify-content: space-between;
		}

		&_totalPrice {
			&_count {
				display: flex;
				align-items: center;
				gap: 0.8rem;

				& > button {
					display: flex;
					justify-content: center;
					align-items: center;

					&:nth-of-type(1) {
						transform: rotate(180deg);
					}

					& > svg {
						width: 1.6rem;
						& > g > path {
							fill: $color1;
						}
					}
				}
			}
		}
	}

	&_cross {
		width: 1.25rem;
		height: 1.25rem;
		position: absolute;
		top: 0.8rem;
		right: 1.5rem;

		&::after,
		&::before {
			position: absolute;
			content: '';
			width: 1.75rem;
			height: 2px;
			top: 0.563rem;
			left: -0.25rem;
			background-color: #d3d3da;
			border-radius: 2px;
			transform: rotate(45deg);
		}
		&::after {
			transform: rotate(-45deg);
		}
		&:hover {
			cursor: pointer;
		}
	}
}

// Mobile
@media (max-width: 500px) {
	.purchasing {
		&_servicePayBlock {
			& > div {
				&:nth-of-type(1) {
					flex-direction: column;
					align-items: flex-start;
					gap: 1rem;
				}

				&:nth-of-type(2) {
					flex-direction: column-reverse;
					align-items: flex-end;
					gap: 1rem;

					& > button {
						width: 100%;
					}
				}
			}
		}
	}

	.cartCard {
		&_price {
			grid-template-columns: 1fr;
		}
	}
}
