footer {
	padding: 5rem 0 0;
	display: flex;
	justify-content: space-between;
	align-items: center;
	// width: 72%;
	font-family: 'Roboto';
	font-size: 1.2rem;

	& > div {
		display: flex;
		flex-direction: column;
		// gap: 1.5rem;
		// width: 70%;
		color: #000;
		// letter-spacing: 0.36px;
		& > p {
			display: flex;
			flex-direction: column;
		}

		div {
			display: flex;
			gap: 3rem;
		}
	}

	& > svg {
		width: 10rem;
		height: 7rem;
	}
}
