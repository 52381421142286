.btn {
	padding: 1.5rem 1.2rem;
	display: flex;
	align-items: center;
	background-color: #fff;
	border-radius: 1rem;
	box-sizing: border-box;
	&:hover {
		cursor: pointer;
	}

	& > svg {
		width: 2rem;
		height: 1.6rem;
	}
}
