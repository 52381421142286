@import '../../../css/variables/variables.scss';

.btn {
	padding: 1rem 1.9rem;
	display: flex;
	justify-content: center;
	align-items: center;
	width: 100%;
	font-family: 'Mulish';
	font-style: normal;
	font-weight: 500;
	font-size: 1.6rem;
	line-height: 2rem;
	color: #fff;
	background: $color4;
	border: 1px solid $color4;
	border-radius: 1rem;
	box-sizing: border-box;
}
.btn:hover {
	cursor: pointer;
	color: $color4;
	background: #fff;
}
.btn:active {
	color: #fff;
	background: $color4;
}

/* Tablet */
@media (max-width: 900px) {
	.btn {
		// padding: 0.6rem 1.9rem;
	}
}
