.grid {
	display: grid;
	grid-template-columns: 70% 1fr;
	grid-template-areas: 'main rightPanel' 'footer rightPanel';
	gap: 2rem;

	& > main {
		grid-area: main;
	}

	& > footer {
		grid-area: footer;
	}

	& > section {
		grid-area: rightPanel;
	}
}

// Tablet
@media (max-width: 900px) {
	.grid {
		grid-template-columns: 100%;
		gap: 0;
		position: relative;
	}
}
