@import '../../../../css/variables/variables.scss';
.userInfo {
	display: flex;
	flex-direction: column;
	gap: 1.875rem;

	& > div:nth-of-type(1) {
		display: flex;
		justify-content: space-between;
		align-items: center;
		& > p {
			font-size: 12px;
			color: #797979;
		}
	}

	&_content {
		display: grid;
		grid-template-areas: 'info edit' 'info card' 'info card';
		gap: 2rem 0;

		&_info {
			grid-area: info;
			display: flex;
			flex-direction: column;
			gap: 1rem;

			& > label {
				display: flex;
				gap: 1rem;
				text-wrap: nowrap;
				& > input {
					// display: block;
					// width: max-content;
					&:disabled {
						color: #000;
						border: none;
					}
				}
			}
			& > span {
				font-size: 1.2rem;
				color: red;
				& > button {
					font-size: 1.2rem;
					color: red;
					text-decoration: underline;
					&:hover {
						cursor: pointer;
					}
				}
			}

			&_error {
				color: red;
			}
		}

		&_edit {
			grid-area: edit;
			justify-self: end;
			display: flex;
			align-items: center;
			gap: 1rem;

			& > svg {
				width: 3rem;
				height: 3rem;

				& > g > path {
					fill: $color4;
				}
			}

			&_cross {
				grid-area: edit;
				justify-self: end;
				width: 3rem;
				height: 3rem;
				position: relative;

				&::after,
				&::before {
					position: absolute;
					content: '';
					width: 3rem;
					height: 2px;
					top: 1.4rem;
					left: 0rem;
					background-color: #d3d3da;
					border-radius: 2px;
					transform: rotate(45deg);
				}
				&::after {
					transform: rotate(-45deg);
				}
			}
		}

		&_card {
			padding: 1rem 2rem;
			grid-area: card;
			justify-self: end;
			display: flex;
			flex-direction: column;
			gap: 1rem;
			border-radius: 1rem;
			box-shadow: 1px 1px 6px 0px rgba(0, 0, 0, 0.25);
			box-sizing: border-box;

			& > div:nth-of-type(1) {
				display: flex;
				justify-content: space-between;
				align-items: center;

				& > span {
					color: #797979;
				}
				& > p {
					color: #000;
					font-size: 1.8rem;
					font-weight: 500;
				}
			}

			& > input {
				padding: 0.5rem 1.5rem;
				background-color: #f5f5f5;
				border: none;
				border-radius: 0.6rem;
				position: relative;
				// &::after {
				// 	position: absolute;
				// 	content: '₽';
				// 	top: 0;
				// 	right: 0;
				// 	width: max-content;
				// 	color: #000;
				// }
			}

			& > button {
				color: $color4;
				background: none;
				border: none;

				&:active {
					color: $color4;
					background: none;
				}
			}
		}
	}
}

@media (max-width: 900px) {
	.userInfo {
		& > h3 {
			font-size: 2.6rem;
		}

		&_content {
			grid-template-areas: 'info edit' 'card edit';

			&_edit {
				align-self: flex-start;
				& > p {
					display: none;
				}
			}

			&_card {
				justify-self: start;
			}
		}
	}

	.edit > p {
		display: none;
	}
	.edit > svg {
		width: 2em;
		height: 2em;
	}
}

@media (max-width: 500px) {
	.userInfo {
		& > div:nth-of-type(1) {
			flex-direction: column;
			align-items: flex-start;
		}
	}
}
