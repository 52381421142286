.titleSection {
	display: flex;
	flex-direction: column;
	gap: 3rem;

	&_content {
		padding: 3.5rem 4.5rem;
		display: flex;
		flex-direction: column;
		justify-content: space-between;
		width: 100%;
		height: 50rem;
		color: white;
		background-size: cover;
		background-repeat: no-repeat;
		border-radius: 2.4rem;
		box-sizing: border-box;

		&_imgWrp {
			display: flex;
			justify-content: space-between;
			align-items: flex-start;

			& > svg {
				width: 10rem;
				height: 7rem;

				g > path,
				g > g > path {
					fill: #fff;
				}
			}

			& > img {
				width: 35rem;
				height: 25rem;
			}
		}

		&_titleWrp {
			display: flex;
			flex-direction: column;
			gap: 1rem;

			h2 {
				// font-size: 5rem;
				// font-weight: 700;
				text-shadow: 2px 2px 0px rgba(0, 0, 0, 0.75);
			}

			p {
				font-size: 1.8rem;
				font-weight: 500;
				text-shadow: 2px 2px 0px rgba(0, 0, 0, 0.75);
			}
		}

		& > button.linkArrow {
			display: none;
		}
	}

	& > p {
		font-size: 2rem;
		font-weight: 500;
		line-height: 3.3rem;
	}
}
// Tablet
@media (max-width: 900px) {
	.titleSection {
		&_content {
			position: relative;

			&_imgWrp {
				& > svg {
					width: 8rem;
					height: 5.5rem;
				}
			}

			&_titleWrp {
				// & > h2 {
				// 	font-size: 4rem;
				// }
				& > p {
					font-size: 1.6rem;
				}
			}
		}

		& > p {
			font-size: 1.7rem;
		}
	}
}

// Mobile
@media (max-width: 500px) {
	.titleSection {
		&_content {
			padding: 2rem 1.5rem;
		}
		&_content_imgWrp {
			flex-direction: column;
			gap: 1rem;

			& > img {
				align-self: center;
			}
		}
	}
}
